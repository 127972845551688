<template>
  <div class="outerDiv" :style="configStyle">

    <div v-if="!loggedIn">
        <div class="logoArea">
            <img alt="school logo" :src="schoolLogoFilePath"  class="logotop" >
        </div>
        
        <loginuser @loggedin='afterLogin()' ref='login'/>
    </div>
    <div v-if="loggedIn" >
      <div class="header">
        <div class="logoArea">
            <img alt="school logo" :src="schoolLogoFilePath"  class="logo" >
        </div>
        
        <div class="navArea horizFlex">
          <div v-for="menuitem in menu" v-bind:key="menuitem.name" v-bind:menuitem="menuitem">
            <button :class="menuClass(menuitem)" v-if="!menuitem.newTab" @click="showAdmin(menuitem)" :disabled="disableMenuItem(menuitem)">{{menuitem.name}}</button>
            <button :class="menuClass(menuitem)" v-if="menuitem.newTab" :disabled="disableMenuItem(menuitem)">
              <a :href="getUrl(menuitem)" target="_blank"   class="menubutton"  v-if="!disableMenuItem(menuitem)">
                <label class="buttontext">{{menuitem.name}}</label>
              </a>
              <label class="buttontext" v-if="disableMenuItem(menuitem)">{{menuitem.name}}</label>
            </button>  
          </div>
          <button :class="linkClass()"  @click="showLinks"  v-if="showLinkButton">Important Links</button>
        </div>
        

        <div class=" vertFlex rightjustified" v-if="loggedIn">
            <p class="userlabel bold">{{loggedInUser.Name}}</p>
            <p class="userlabel" v-if="loggedInUser.isAdmin">ADMIN</p>       
            <linkbutton @click="logout()" toplabel="Logout"  />
            <p class="userlabel" >{{currentTime}}</p>
            <!-- <updatepassword :isAdminChangePassword="false"   :username="username" /> --> 
        </div>
        <div v-if="wasUpdated" class="refresh">
            <p class=" userlabel update">Updates are available for your application. Please click Refresh to download the latest version.</p>
            <button @click="refreshapp()">Refresh</button>
        </div>
      </div>
      
      
      <div id="wrap">
        <iframe v-for="menuitem in menu"   @load="onloadiframe"
          :class="frameclass(menuitem)" id="menuitem.id"  v-show="currentAdmin==menuitem.name"
            :src="getUrl(menuitem)" allow="camera" 
          v-bind:key="menuitem.name"
          v-bind:menuitem="menuitem"  ref="iframes"
        />
        <div v-show="currentAdmin=='Links'" class="linkdiv">
          <div class="leftjustified ">
            <div v-for="link in linkList" v-bind:key="link.name" class=" linkgrid linkText">
              <label class="linkname">{{link.name}}</label>
              <a :href="link.url" target="_blank" >
                  <label >{{link.url}}</label>   
              </a>
              <confirm :showImageButton="trueParam" :body="getRemoveLinkString(link.name)" title="Remove Link" @yes="RemoveLink(link.id)" />
            </div>
          </div>
          <smallbutton   @click="startAddLink()" toplabel="Add New Link"/>
          <div v-if="showAddLink" >
              <formentry  inputtype="text"  label="Name" isrequired='True' v-model="newAdminLink.name" />
              <formentry  inputtype="text"  label="Url" isrequired='True' v-model="newAdminLink.url" />
              <div>
                <smallbutton toplabel="Save" class="innerbutton" @click="saveLink()"/>
                <smallbutton toplabel="Cancel" class="innerbutton" @click="cancelLink()"/>
              </div>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import loginuser from './login/loginuser.vue'
import datamethods from '../services/datamethods'
import {mapFields} from 'vuex-map-fields'
import style from '../style.js'
import linkbutton from '../components/controls/linkbutton.vue'
import smallbutton from '../components/controls/button.vue'
import formentry from '../components/controls/entry.vue'
import confirm from '../components/actions/confirm.vue'
export default {
  name: 'Start',
  components:{
    loginuser,
    linkbutton,
    smallbutton,
    formentry,
    confirm
  },
  mixins:[datamethods,style],
   data(){
        return{
          
          menu:[],
          adminAccess:[],
          currentMenuItem:{},
          currentAdmin:"",
          currentTime:'',
          linksSelected:false,
          linkList:[],
          showAddLink:false,
          newAdminLink:{
            id:0,
            name:null,
            url:null
          },
          trueParam:true
  
        }
   },
   computed:{
        ...mapFields([
            'loggedIn',
            'username',
            'oldpassword',
            'loggedInUser',
            'loggedInUser.isAdmin',
            'logoOverride',
            'token',
            'wasUpdated'
        ]),
        schoolLogoFilePath(){           
          return './../' + window.SCHOOL_LOGO
        },
        showLinkButton(){
          return this.menu.length>0
        }
    },
   methods:{
     getRemoveLinkString(name){
        return 'Do you want to remove ' + name + '?'
    },
    async RemoveLink(ID){
        this.responseMessage=null
        console.log('inremove')
        let req = {
            AdminLink:{
              id:ID
            }
        }
        var response = await this.callAPI(req,'deleteadminlink')
        if(response.Successful){
            this.getLinks()
        }else{
            this.responseMessage = response.Message
        }
        this.pleaseWait=false;
    },
    startAddLink(){
      this.showAddLink=true
      this.newAdminLink={
        id:0,
        name:null,
        url:null
      }
    },
    async saveLink(){
           if (this.newAdminLink.name==null || this.newAdminLink.url==null) {return}
            this.showAddLink=false

            let req = {
                AdminLink:this.newAdminLink

            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'addadminlink')
            //handle response
            if(response.Successful){

                 this.getLinks()
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;  

        },
        cancelLink(){
            this.showAddLink=false

        },
    updateTime(){
      var dt = new Date()
      const Geeks = { weekday: 'long', year: 
    'numeric', month: 'long', day: 'numeric' };
      var dtstr = new Intl.DateTimeFormat('en-GB',Geeks).format(dt);
      this.currentTime=dtstr
    },
    disableMenuItem(menuitem){
      var disable = true
      var appName = menuitem.appName
      this.adminAccess.forEach(myFunction2)
      function myFunction2(item, index, arr) {
          let aa=arr[index]
          if(aa.appName==appName && aa.appComponentName=='main'){
            //console.log(appName + ' ' + aa.canView)
              disable=!aa.canView
          }
      } 
      return disable
    },
    onloadiframe(frame){
      //frame.style.height= frame.contentWindow.document.body.scrollHeight + 'px';
    },
    frameclass(menuitem){
      if(menuitem.showNarrow){return "framenarrow"}
      else{return "frame"}
    },
      getUrl(menuitem){
        var outurl = menuitem.url + "?token=" + this.token + "&header=false"
        
        //console.log(outurl)
        return outurl
      },
      menuClass(menuitem){
        if(this.disableMenuItem(menuitem)){return 'menubuttondisabled'}
        if(menuitem.selected){return 'menubuttonselected'}
        else{return 'menubutton'}
      },
      linkClass(){
        if(this.linksSelected){return 'menubuttonselected'}
        else{return 'menubutton'}

      },
      showAdmin(menuitem, sendRefresh=true){
        this.linksSelected=false
        this.currentMenuItem.selected=false
        this.currentMenuItem=menuitem
        menuitem.selected=true
        this.currentAdmin=menuitem.name

        //send refresh message
        var index = this.getIndexOfMenu(menuitem.appName)
        var iframe = this.$refs.iframes[index]
        const message = JSON.stringify({
            to:menuitem.appName,
            action:'refresh',
            id:0
        })
        if(sendRefresh){
          console.log('send msg ' + index + ' '  +  message)
          iframe.contentWindow.postMessage(message,"*")
        }
        
      },
      showLinks(){
        this.currentMenuItem.selected=false
        this.linksSelected=true
        this.currentAdmin="Links"
      },
      afterLogin(){
        console.log('afterlogin')
        this.currentAdmin=""
        this.getAdminAccess()
        this.getLinks()
      },
      async getMenu(){
            this.responseMessage='';
            var response = await this.callAPI(null,'menu')
            //handle response
            if(response.Successful){
                this.menu=response.MenuItems
            }else{
                
            }
           
      },
      async getLinks(){
            this.responseMessage='';
            var response = await this.callAPI(null,'adminlinks')
            //handle response
            if(response.Successful){
                this.linkList=response.AdminLinks
            }else{
                
            }
           
      },
      async getAdminAccess(){
        var id = this.loggedInUser.user_personId
        if(id==null){id = this.loggedInUser.ID}
        let req = {
                person:{
                  ID:id
                }
            }
            
            this.responseMessage='';
            var response = await this.callAPI(req,'adminaccess')
            //handle response
            if(response.Successful){
                this.adminAccess=response.adminAccessList
                this.getMenu()
            }else{
                
            }
           
      },
      logout(){
        console.log('start logout...')
        this.loggedIn=false;
          this.$refs.login.logout()
          
      },
      receiveMessage(event){
        try{
            var msg =  JSON.parse(event.data)
        }
        catch(err){
            return
        }
        var msg =  JSON.parse(event.data)
        var name = msg.to
        if(name !=undefined){
          

          console.log('out msg' + event.data)
          var index = this.getIndexOfMenu(name)

          var iframe = this.$refs.iframes[index]
          iframe.contentWindow.postMessage(event.data,"*")

          this.showAdmin(this.menu[index],false)
        }
        
      },
      getIndexOfMenu(menuName){
        var outindex = -1
        this.menu.forEach(myFunction2)
        function myFunction2(item, index, arr) {
            let aa=arr[index]
            if(aa.appName==menuName ){
           
                outindex = index
            }
        } 
        console.log('index ' + outindex)
        return outindex
      },
      checkLastUpdate(){
        var needsClear =false

        //get the last update value from config
        var lastUpdate = '' 
        if(window.LAST_UPDATE_DATE!=undefined){lastUpdate=window.LAST_UPDATE_DATE}
        
        //if we don't have a setting, then don't do anything
        if(lastUpdate==''){return}


        //get last update value from localstorage
        var storedLastUpdate = ''
        storedLastUpdate=localStorage.getItem('lastUpdate')

        console.log('lastupdate ' + lastUpdate + ' ' + storedLastUpdate)

        if(storedLastUpdate==undefined){needsClear=true}
        else if(storedLastUpdate!=lastUpdate){needsClear=true}

        //if we need to clear...
        if(needsClear){
          console.log('App updated. Needs refresh')
          //set flag in store
          this.wasUpdated=true
        }
        
        //store the last update
        localStorage.setItem('lastUpdate',lastUpdate)
        //console.log('writing last update to local storage')
      },
      refreshapp(){
        //send msgs to all frames
        this.sendAppUpdateMessages()

        
      },
      sendAppUpdateMessages(){
        var mythis = this

        this.menu.forEach(myFunction2)
        function myFunction2(item, index, arr) {
            let aa=arr[index]
            mythis.sendAppUpdateMessage(aa.appName)
        }

        //reload self
        console.log('refresh self')
        location.reload(true);
        
      },
      sendAppUpdateMessage(appName){
        var index = this.getIndexOfMenu(appName)
        var iframe = this.$refs.iframes[index]
        const message = JSON.stringify({
            to:appName,
            action:'appupdate',
            id:0
        })

        console.log('send msg ' + index + ' ' + appName + ' ' +  message)
        iframe.contentWindow.postMessage(message,"*")
      }
   },
  
   mounted(){
    console.log('in start mount')
      this.setColors()

      this.updateTime()
    
      window.addEventListener('message', this.receiveMessage)

      //change password
      let urlParams = new URLSearchParams(window.location.search)
      let tmp = urlParams.get('email')
        if(tmp!=null){
            this.username = tmp;
        }

        let tmp2 = urlParams.get('temp')
        if(tmp2!=null){
            this.oldpassword = tmp2;
            this.$nextTick(()=>{
            //this.changepass()
            this.$refs.login.changepassword();
            });
        }

      //update
      this.checkLastUpdate()
   }
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";
.linkdiv{
  display:flex;
  flex-direction:column;
  align-items: center;
  width:100%;
}
.linkgrid{
  display:grid;
  grid-template-columns: 300px auto 50px;
}
.linkname{
  margin-right:30px;
}
.linkText{
  margin:20px;
}
.disabled, .disabled:hover {
   pointer-events: none;
}
.frame{
  width: 100%;
  height: 100%;
  
  border:none;
}
.framenarrow{
  width: 380px;
  height: 100%;
  
  border:none;
}
#wrap {
  height:88vh;
  width:100vw;
}
body{
  height:95vh; 
  height: -webkit-fill-available;
  width:100vw;
  padding:0px;
  margin:0px;
  font-size:medium;
  color:var(--text-color);
}
.logo{
  height:60px;
  margin:8px;
}
.logotop{
  margin-top:40px;
  width:400px;
}
.outerDiv{
  margin:0px;
  padding:0px;
    height:95vh;
    display:grid;
    /* grid-template-rows:140px auto 90px;
    grid-template-areas: 
        "header header header"
        "center center center"
        "footer footer footer"; */
}
.header{
    grid-area:header;
    padding:10px 20px 10px 10px;
    display:grid;
    grid-template-columns:2fr 4fr 2fr;
    grid-template-rows:auto auto;
    grid-template-areas: 
        "logo nav user"
        "refresh refresh refresh";
    font-size:medium;
    background-color:var(--background-color);
    color:var(--header-text-color);
}
.refresh{
  grid-area:refresh;
}
.navArea{
    padding:0px;
    margin:0px;
}
.userArea{
  grid-area:user;
}
.userlabel{
  margin:0px;
  color:var(--header-text-color);
}
.menubutton{
  border: none;
    border-radius:2px;
    width:95px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px 2px 10px 2px;
  text-decoration: none;
  white-space:normal;
  color:var(--header-button-text-color);
  background-color:var(--header-button-color);
}
.menubuttonselected{
  border: 1px solid darkslategray;
    border-radius:2px;
    width:95px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px 2px 10px 2px;
  text-decoration: none;
 
 color:black;
}
.menubuttondisabled{
  border: none;
    border-radius:2px;
    width:95px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px 2px 10px 2px;
  text-decoration: none;
  color:var(--header-button-text-color);
  background-color:var(--inactive-color);
}
</style>
/*           badgeUrl:"https://mobileidadmin-schoolvision.azurewebsites.net",
          fundingUrl:"https://fundingadmin-schoolvision.azurewebsites.net",
          gradeUrl:"https://schoolvisiongrading.schoolvision.net",
          timeUrl:"https://timeadmin-schoolvision.schoolvision.net/",
          mobileidUrl:"https://mobileid-schoolvision.azurewebsites.net", */

                <!-- <button @click="showAdmin('grade')" >grade</button>
      <button @click="showAdmin('time')" >time</button>
      <button @click="showAdmin('badge')" >badge</button>
      <button @click="showAdmin('funding')" >Funding</button>
      <button @click="showAdmin('mobileid')" >mobileid</button> -->

            <!-- <iframe :src="badgeUrl" class="frame" v-show="currentAdmin=='badge'"/>
      <iframe :src="fundingUrl" class="frame" v-show="currentAdmin=='funding'"/>
      <iframe :src="gradeUrl" class="frame" v-show="currentAdmin=='grade'"/>
      <iframe :src="timeUrl" class="frame" v-show="currentAdmin=='time'"/>
      <iframe :src="mobileidUrl" class="frame" v-show="currentAdmin=='mobileid'"/> -->